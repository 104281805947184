// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/clock.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/radio.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/img/radio-active.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".index_price_2JEGo{margin-top:.64rem;font-size:1.01333rem;font-weight:600;line-height:1.41333rem;letter-spacing:.02667rem}.index_price_2JEGo,.index_time_3EGWw{text-align:center}.index_time_3EGWw{display:flex;align-items:center;justify-content:center;margin:.16rem 0 .85333rem 0;font-size:.32rem;color:#a6a6a6}.index_time_3EGWw .index_iconClock_2XLhN{width:.37333rem;height:.37333rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;margin-right:.18667rem}.index_pay_160Wa{width:8.93333rem;height:1.33333rem;margin:0 auto .32rem;box-sizing:border-box;background:#212121;border-radius:.32rem;padding:0 .72rem 0 .58667rem;display:flex;align-items:center;justify-content:space-between}.index_pay_160Wa .index_icon_2v__O{width:.69333rem;height:.69333rem;margin-right:.32rem;vertical-align:middle}.index_pay_160Wa .index_raido_1CbpG{width:.48rem;height:.48rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:contain}.index_pay_160Wa .index_raido_1CbpG.index_active_p2IyQ{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.index_payBtn_2wDbQ{position:fixed;bottom:.96rem;left:.53333rem;width:8.93333rem;height:1.17333rem;line-height:1.17333rem;text-align:center;background:#212121;box-shadow:0 .53333rem .8rem 0 rgba(0,0,0,.4);border-radius:.74667rem;font-size:.42667rem}", ""]);
// Exports
exports.locals = {
	"price": "index_price_2JEGo",
	"time": "index_time_3EGWw",
	"iconClock": "index_iconClock_2XLhN",
	"pay": "index_pay_160Wa",
	"icon": "index_icon_2v__O",
	"raido": "index_raido_1CbpG",
	"active": "index_active_p2IyQ",
	"payBtn": "index_payBtn_2wDbQ"
};
module.exports = exports;
