<template>
  <div>
    <Back title="支付订单" />
    <p :class="$style.price">¥{{ price }}</p>
    <!-- <p :class="$style.time">
      <i :class="$style.iconClock"></i>
      <span>剩下时间 4:59:59</span>
    </p> -->
    <p :class="$style.time"></p>
    <div
      :class="$style.pay"
      @click="handleChoosePay(item.type)"
      v-for="item in payList"
      :key="item.type"
    >
      <div>
        <img :class="$style.icon" :src="item.icon" />
        <span>{{ item.name }}</span>
      </div>
      <i
        :class="[$style.raido, { [$style.active]: payType === item.type }]"
      ></i>
    </div>
    <div :class="$style.payBtn" @click="handlePay">确认支付</div>
  </div>
</template>

<script>
import Back from "@/components/Back";
import { order } from "@/api";
import { getStorage, isInWX } from "@/utils/util";

export default {
  components: {
    Back,
  },
  data() {
    return {
      dId: this.$route.query.ddc_id,
      price: this.$route.query.price,
      payList: [
        {
          name: "微信支付",
          icon: require("@/assets/img/wepay.png"),
          type: "1",
        },
        {
          name: "支付宝",
          icon: require("@/assets/img/alipay.png"),
          type: "2",
        },
      ],
      payType: "1",
    };
  },
  methods: {
    handleChoosePay(type) {
      this.payType = type;
    },
    handlePay() {
      // 支付宝支付
      if (Number(this.payType) === 2) {
        this.handlePayAli();
        return;
      }
      const isWx = isInWX();
      if (isWx) {
        this.handlePayInWx();
      } else {
        this.handlePayOutWx();
      }
    },
    // 微信内支付
    handlePayInWx() {
      const openid = getStorage("openid");
      const data = {
        ddc_id: this.dId,
        payment_channel: this.payType,
        openid: openid,
        payment_state: 2, // payment_state: 支付环境:1-微信外浏览器,2-微信内, 3-支付宝网页支付, 4-支付宝APP
      };
      order(data).then((res) => {
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              // eslint-disable-next-line no-undef
              onBridgeReady,
              false
            );
          } else if (document.attachEvent) {
            // eslint-disable-next-line no-undef
            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
            // eslint-disable-next-line no-undef
            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
          }
        } else {
          this.onBridgeReady(res);
        }
      });
    },
    onBridgeReady(data) {
      const { appId, timeStamp, nonceStr, signType, paySign } = data;
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId, //公众号名称，由商户传入
          timeStamp, //时间戳，自1970年以来的秒数
          nonceStr, //随机串
          package: data.package,
          signType, //微信签名方式;
          paySign, //微信签名
        },
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            //do something
            this.$router.push("/order");
          } else {
            //支付失败处理
            this.$router.push("/order");
          }
        }
      );
    },
    // 微信外支付
    handlePayOutWx() {
      const data = {
        ddc_id: this.dId,
        payment_channel: this.payType,
        payment_state: 1, // payment_state: 支付环境:1-微信外浏览器,2-微信内, 3-支付宝网页支付, 4-支付宝APP
      };
      order(data).then((res) => {
        location.href = res.h5_url;
      });
    },
    handlePayAli() {
      const data = {
        ddc_id: this.dId,
        payment_channel: this.payType,
        payment_state: 3, // payment_state: 支付环境:1-微信外浏览器,2-微信内, 3-支付宝网页支付, 4-支付宝APP
      };
      order(data).then((res) => {
        const div = document.createElement("div");
        /* 此处form就是后台返回接收到的数据 */
        div.innerHTML = res;
        document.body.appendChild(div);
        document.forms[0].submit();
      });
    },
  },
};
</script>

<style lang="scss" module>
.price {
  text-align: center;
  margin-top: 24px;
  font-size: 38px;
  font-weight: 600;
  line-height: 53px;
  letter-spacing: 1px;
}
.time {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px 0 32px 0;
  text-align: center;
  font-size: 12px;
  color: #a6a6a6;
  .iconClock {
    width: 14px;
    height: 14px;
    background-image: url("~@/assets/img/clock.png");
    background-size: contain;
    margin-right: 7px;
  }
}
.pay {
  width: 335px;
  height: 50px;
  margin: 0 auto 12px;
  box-sizing: border-box;
  background: #212121;
  border-radius: 12px;
  padding: 0 27px 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon {
    width: 26px;
    height: 26px;
    margin-right: 12px;
    vertical-align: middle;
  }
  .raido {
    width: 18px;
    height: 18px;
    background-image: url("~@/assets/img/radio.png");
    background-size: contain;
    &.active {
      background-image: url("~@/assets/img/radio-active.png");
    }
  }
}
.payBtn {
  position: fixed;
  bottom: 36px;
  left: 20px;
  width: 335px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: #212121;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.4);
  border-radius: 28px;
  font-size: 16px;
}
</style>
